// if we need any other/more breakpoints, consider this for a good solution: https://css-tricks.com/approaches-media-queries-sass/
$medium-width: 541px;
$large-width: 1024px;

@mixin small-screen {
  @media (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin medium-screen {
  @media (min-width: #{$medium-width}) and (max-width: #{$large-width - 1px}) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: #{$large-width}) {
    @content;
  }
}

@mixin image-drop-shadow {
  -webkit-filter: drop-shadow(0.2rem 0.1rem 0.1rem rgba($ranked-background-color-dark, 0.7));
  filter: drop-shadow(0.2rem 0.1rem 0.1rem rgba($ranked-background-color-dark, 0.7));
}
