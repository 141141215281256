@import "global-variables";

/*
 * Theme variables
 */
 $theme-variables: (
  primaryColor: var(--primaryColor),
  primaryColorLight: var(--primaryColorLight),
  primaryColorGray: var(--primaryColorGray),
  secondaryColor: var(--secondaryColor),
  thirdColor: var(--thirdColor),
  grayColor: var(--gray),
  textColor: var(--secondaryColor),
  textColorDark: var(--secondaryColorDark),
  textColorLight: var(--secondaryColorLight),
  inverseTextColor: var(--thirdColor),
  shadowColor: var(--shadowColor),
  lightColor: var(--lightColor),
);

$header-height: 8vh;

@function themed($key) {
  @return map-get($theme-variables, $key);
}
