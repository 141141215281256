@import "variables";

.imprint-privacy {
  text-align: center;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  max-width: 30rem;
  width: 80%;
  margin: 0 auto;

  .section {
    font-size: 0.6rem;
    text-align: left;
    display: inline-block;

    .title {
      font-size: 1.5rem;
      font-weight: 500;
    }

    h1 {
      font-size: 0.9rem;
      font-weight: 500;
      margin-block-start: 1.2rem;
      margin-block-end: 0.2rem;
    }

    h2 {
      font-size: 0.8rem;
      font-weight: 500;
      margin-block-end: 0.2rem;
    }

    h3 {
      font-size: 0.6rem;
      margin-block-end: 0rem;
    }

    p {
      margin-block-start: 0;

      a {
        color: $primary-color;
      }
    }
  }
}
