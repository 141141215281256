@import "variables";
@import "mixins";
@import "shared-ui";
@import "fonts";

// need to defined the imprint styling globally because the HTML is translated and inserted via innerHTML
@import "imprint";

.r-hide {
  display: none;
  visibility: hidden;
}

.r-fade-in-animation {
  opacity: 0;
  transform: translateY(10%);

  &.r-animated {
    transform: translateY(0);
    opacity: 1;
    transition: opacity 1s ease, transform 1s ease;
  }
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  position: relative;

  @include medium-screen {
    font-size: 20px;
  }

  @include small-screen {
    font-size: 18px;
  }
}

body {
  background-color: $ranked-background-color-light;
  color: $ranked-background-color-dark;
  box-sizing: border-box;

  a {
    text-decoration: underline;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  .r-headline {
    font-size: 2.5rem;
    font-weight: 900;
    padding: 0 1rem;
  }

  .r-primary-color {
    color: $primary-color;
  }

  .r-blur-background {
    background-color: rgba($ranked-background-color-light, 0.7);
    backdrop-filter: blur(0.25rem);

    @supports not (backdrop-filter: blur()) {
      background-color: rgba($ranked-background-color-light, 0.95);
    }
  }
}

input, label {
  // remove the gray flicker overlay when tapping on inputs or labels for inputs
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.theme-global {
  $primary-color-light: mix($primary-color, white, 30);

  --primaryColor: #{$primary-color};
  --primaryColorLight: #{$primary-color-light};
  --primaryColorGray: #{mix($primary-color-light, gray, 70)};
  --secondaryColor: #{$accent-color};
  --secondaryColorDark: #{mix($accent-color, black, 40)};
  --secondaryColorLight: #{mix($accent-color, white, 80)};
  --thirdColor: #{$ranked-background-color-light};
  --gray: #979797;
  --shadowColor: #000000;
  --lightColor: #FFFFFF;
}
